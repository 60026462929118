import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Image from "next/image";
import React from "react";

interface SeriesCoverUploadProps {
  control: any;
  name: string;
  label: string;
  aspectRatio: string;
  height: number;
  width: number;
  currentUrl: string;
  placeholderSrc: string;
}

const SeriesCoverUpload: React.FC<SeriesCoverUploadProps> = ({
  control,
  name,
  label,
  aspectRatio,
  height,
  width,
  currentUrl,
  placeholderSrc,
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <div className="text-[12px] text-[#8b898c] font-medium">
            Aspect ratio: {aspectRatio}
          </div>
          <FormControl>
            <div>
              <Input
                type="file"
                id={`${name}Input`}
                className="hidden"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) field.onChange(file);
                }}
              />
              <button
                type="button"
                onClick={() => {
                  document.getElementById(`${name}Input`)?.click();
                }}
              >
                <div className="relative">
                  <div className="absolute top-0 left-0 right-0 h-5 bg-red-500 opacity-30 flex items-center justify-center">
                    <span className="text-xs text-white">
                      No text or faces in this margin
                    </span>
                  </div>
                  <Image
                    alt={label.toLowerCase()}
                    className={`h-[${height}px] w-[${width}px] ${
                      currentUrl ? "object-cover" : "object-contain"
                    }`}
                    height={height}
                    src={
                      field.value
                        ? URL.createObjectURL(field.value)
                        : currentUrl || placeholderSrc
                    }
                    width={width}
                  />
                  <div className="absolute bottom-0 left-0 right-0 h-5 bg-red-500 opacity-30 flex items-center justify-center">
                    <span className="text-xs text-white">
                      No text or faces in this margin
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default SeriesCoverUpload;
