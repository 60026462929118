import {
  CollectionReference,
  Query,
  QueryConstraint,
  QueryFieldFilterConstraint,
  WithFieldValue,
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useFirestoreCollectionData } from "reactfire";
import editorDatabase from "../utils/firebase/firebaseClientConfig";

export function useFirestoreCollection<T>(
  collectionId: string,
  q?: QueryConstraint[],
  database: any = editorDatabase
) {
  const ref = collection(database, collectionId) as CollectionReference<T>;

  const { status, data } = useFirestoreCollectionData(
    q ? query(ref, ...q) : ref,
    {
      idField: "id",
    }
  );

  function addDocument(newData: T, id?: string) {
    if (id) {
      return setDoc(doc(ref, id), newData);
    }
    return addDoc(ref, newData);
  }

  function removeDocument(documentId: string) {
    return deleteDoc(doc(ref, documentId));
  }

  function updateDocument(documentId: string, newData: Partial<T>) {
    return updateDoc(doc(ref, documentId), {
      ...newData,
      updatedAt: serverTimestamp(),
    });
  }

  return {
    status,
    data: data as T[],
    addDocument,
    removeDocument,
    updateDocument,
  };
}
