import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
  where,
  writeBatch,
} from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import slugify from "slugify";
import { STORAGE_BUCKET_BASE_URL, storage } from "./firebaseClientConfig";
import mobileDatabase, { AUTHORS_COLLECTION } from "./mobileFirebase";

export const getAuthorById = async (authorId: string) => {
  const authorDoc = await getDoc(
    doc(mobileDatabase, AUTHORS_COLLECTION, authorId)
  );

  if (!authorDoc.exists()) return null;

  return authorDoc.data();
};

export const getAuthorsByIds = async (authorIds: string[]) => {
  const authorsQuery = query(
    collection(mobileDatabase, AUTHORS_COLLECTION),
    where("id", "in", authorIds)
  );

  const authorsSnapshot = await getDocs(authorsQuery);

  if (authorsSnapshot.empty) return [];

  return authorsSnapshot.docs.map((doc) => {
    return doc.data();
  });
};

export const createAuthors = async (ids: string[]) => {
  const batch = writeBatch(mobileDatabase);

  for (const id of ids) {
    const authorRef = doc(collection(mobileDatabase, AUTHORS_COLLECTION), id);
    batch.set(authorRef, {
      avatar_url: "",
      bio: "",
      createdAt: serverTimestamp(),
      id,
      isPublishAuthor: false,
      name: "",
      updatedAt: serverTimestamp(),
    });
  }

  await batch.commit();
};

export const createAuthor = async ({
  authorId,
  avatar,
  bio,
  isPublishAuthor,
  name,
}: {
  authorId: string;
  avatar: File | null;
  bio: string;
  isPublishAuthor: boolean;
  name: string;
}) => {
  return runTransaction(mobileDatabase, async (transaction) => {
    const authorDoc = doc(
      collection(mobileDatabase, AUTHORS_COLLECTION),
      authorId
    );

    const authorsSnapshot = await transaction.get(authorDoc);

    if (authorsSnapshot.exists()) {
      throw new Error("Author already exists");
    }

    let avatar_url = "";

    if (avatar) {
      const avatarName = slugify(avatar.name, {
        strict: true,
      });
      const storageRef = ref(
        storage,
        `author-avatars/${new Date().getTime()}-${authorId}-${avatarName}`
      );

      const uploadResult = await uploadBytes(storageRef, avatar);

      avatar_url = STORAGE_BUCKET_BASE_URL + uploadResult.metadata.fullPath;
    }

    return transaction.set(authorDoc, {
      avatar_url,
      bio,
      createdAt: serverTimestamp(),
      id: authorId,
      isPublishAuthor,
      name,
      updatedAt: serverTimestamp(),
    });
  });
};

export const updateAuthor = async ({
  authorId,
  avatar,
  bio,
  isPublishAuthor,
  name,
}: {
  authorId: string;
  avatar: File | null;
  bio: string;
  isPublishAuthor: boolean;
  name: string;
}) => {
  return runTransaction(mobileDatabase, async (transaction) => {
    const authorDoc = doc(
      collection(mobileDatabase, AUTHORS_COLLECTION),
      authorId
    );

    const authorSnapshot = await transaction.get(authorDoc);

    if (!authorSnapshot.exists()) {
      throw new Error("Author does not exist");
    }

    let { avatar_url } = authorSnapshot.data();

    if (avatar) {
      const avatarName = slugify(avatar.name, {
        strict: true,
      });
      const storageRef = ref(
        storage,
        `author-avatars/${new Date().getTime()}-${authorId}-${avatarName}`
      );

      const uploadResult = await uploadBytes(storageRef, avatar);

      avatar_url = STORAGE_BUCKET_BASE_URL + uploadResult.metadata.fullPath;
    }

    return transaction.update(authorDoc, {
      avatar_url,
      bio,
      isPublishAuthor,
      name,
      updatedAt: serverTimestamp(),
    });
  });
};
