"use client";
import { Button } from "@/components/ui/button";
import BaseModal from "@/components/ui/custom/BaseModal";
import { searchUsers } from "@/src/utils/collaboratorUtils";
import type { User } from "@/src/utils/firebase/userOperations";
import { fetchUsersByIds } from "@/src/utils/firebase/userOperations";
import React from "react";

type seriesCollaboratorModalProps = {
  addUser: (userId: string) => Promise<void>;
  currentUserIds: string[];
  isOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  type: "Creators" | "Collaborators";
};

const SeriesUserAddModal = ({
  addUser,
  currentUserIds,
  isOpen,
  setModalOpen,
  type,
}: seriesCollaboratorModalProps) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<User[]>([]);
  const [currentUsers, setCurrentUsers] = React.useState<User[]>([]);

  React.useEffect(() => {
    const loadCurrentUsers = async () => {
      try {
        const users = await fetchUsersByIds(currentUserIds);
        setCurrentUsers(users);
      } catch (error) {
        console.error("Failed to fetch current users:", error);
      }
    };
    if (isOpen) {
      loadCurrentUsers();
    }
  }, [isOpen, currentUserIds]);

  const initiateSearch = async () => {
    const matches = await searchUsers(searchValue);
    if (!matches.length) {
      alert("No user found");
    }
    setSearchResults(matches);
  };

  const handleAddUser = async (userId: string) => {
    await addUser(userId);
    setModalOpen(false);
  };

  if (!isOpen) return null;

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => setModalOpen(false)}
      title={`Add ${type}`}
    >
      <div className="flex flex-col gap-4 p-8 text-white">
        <div className="flex w-full gap-4 mt-8">
          <input
            className="flex-grow items-center rounded-full border border-[#1f1f1f] px-4 py-2 bg-black text-white"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
            value={searchValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                initiateSearch();
              }
            }}
          />

          <Button variant="default" size="default" onClick={initiateSearch}>
            Search
          </Button>
        </div>

        {/* List of search results */}
        {searchResults
          .filter((user) => !currentUserIds.includes(user.uid ?? ""))
          .map((user) => (
            <div className="flex items-center gap-4" key={user.uid}>
              <div className="text-[16px] font-semibold text-white">
                {user.email}
              </div>
              <Button
                variant="default"
                size="default"
                onClick={() => user.uid && handleAddUser(user.uid)}
              >
                Add {type}
              </Button>
            </div>
          ))}

        {/* List of current users */}
        <h3 className="font-semibold mt-6 mb-2">Current {type}:</h3>
        <ul className="space-y-2">
          {currentUsers.map((user) => (
            <li key={user.uid} className="flex items-center">
              <span className="text-[16px]">{user.email}</span>
            </li>
          ))}
        </ul>
      </div>
    </BaseModal>
  );
};

export default SeriesUserAddModal;
