import { fetchComicUserIdAndCollaboratorIds } from "../utils/firebase/comicsOperations";
import { fetchAllUserSeriesCollaborationsAndCreations } from "../utils/firebase/seriesFetchOperations";
import {
  checkIsShortbreadArtist,
  fetchIsAdminUser,
  fetchIsUserCreatorOfComic,
} from "../utils/firebase/userOperations";

export const getIsAuthorized = async (userId: string) => {
  const [isAdminUser, isShortbreadArtist] = await Promise.all([
    fetchIsAdminUser(userId),
    checkIsShortbreadArtist(userId),
  ]);

  return isAdminUser || isShortbreadArtist;
};


export const userCanSeeScriptButton = async (userId: string) => {
  const [isAdminUser, isUserPublish, seriesWithUser, isUserCreatorOfComic] =
    await Promise.all([
      fetchIsAdminUser(userId),
      checkIsShortbreadArtist(userId),
      fetchAllUserSeriesCollaborationsAndCreations(userId),
      fetchIsUserCreatorOfComic(userId),
    ]);

  return (
    isAdminUser ||
    isUserPublish ||
    seriesWithUser.length > 0 ||
    isUserCreatorOfComic
  );
};

export const userCanSeeSeriesButton = async (userId: string) => {
  const [isAdminUser, isUserPublish, seriesWithUser, isUserCreatorOfComic] =
    await Promise.all([
      fetchIsAdminUser(userId),
      checkIsShortbreadArtist(userId),
      fetchAllUserSeriesCollaborationsAndCreations(userId),
      fetchIsUserCreatorOfComic(userId),
    ]);

  return (
    isAdminUser ||
    isUserPublish ||
    seriesWithUser.length > 0 ||
    isUserCreatorOfComic
  );
};

export const checkCanUserEditComic = async (
  userId: string,
  comicId: string
) => {
  const [isAdminUser, isShortbreadArtist, userIdAndCollaboratorIds] =
    await Promise.all([
      fetchIsAdminUser(userId),
      checkIsShortbreadArtist(userId),
      fetchComicUserIdAndCollaboratorIds(comicId),
    ]);

  return (
    isAdminUser ||
    isShortbreadArtist ||
    userIdAndCollaboratorIds.includes(userId)
  );
};
