export const GENERATION_HISTORY_SCROLL_AMOUNT = 200;

export const DEFAULT_PAGE_HEIGHT = 1300;

export const ZOOM_STEP = 1.25;

export const ZOOM_MIN = 0.0625;

export const ZOOM_MAX = 16;

export const BUBBLE_Z_INDEX_BASE = 10_000;

export const ACTIVE_ELEMENT_Z_INDEX = 20_000;

export const SNAP_THRESHOLD = 3;

export const CLOSE_BY_THRESHOLD = 600;
