import type { ComicType } from "@/src/constants/types/editorTypes";
import {
  type CollectionReference,
  type DocumentData,
  collection,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { Character } from "../constants/types/characterTypes";
import editorDatabase, {
  CHARACTERS_COLLECTION,
} from "./firebase/firebaseClientConfig";

export const EMPTY_PROMPT: string = "";
export const DEFAULT_NEGATIVE_PROMPT: string = "";

export const convertPxToNumber = (value: any): number => {
  if (Number.isNaN(value)) {
    return 64;
  }
  if (typeof value === "number") {
    return value;
  }
  const numericalValue = Number(value.replace("px", ""));
  return numericalValue;
};

export const getImageDimensions = (
  url: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function (this: any) {
      resolve({
        width: this.width,
        height: this.height,
      });
    };

    img.onerror = () => {
      reject(new Error(`Failed to load image with URL ${JSON.stringify(url)}`));
    };

    img.src = url;
  });
};

export const extractCharactersFromPanels = (
  comicPanel: Partial<ComicType>
): Character[] => {
  return [
    ...new Set(
      comicPanel.regions
        ?.flatMap((region) => region.sdParameters.loras ?? [])
        .filter((lora) => lora && lora.characterName.trim() !== "")
    ),
  ];
};

export const scaleComicToOriginal = (
  comicPanel: ComicType,
  ratio: number
): ComicType => {
  const scaleValue = (value: number | undefined, defaultValue = 0) =>
    (value ?? defaultValue) / ratio;

  return {
    ...comicPanel,
    regions: comicPanel.regions.map((region) => ({
      ...region,
      position: {
        y: scaleValue(region.position.y),
        x: scaleValue(region.position.x),
      },
      size: {
        w: scaleValue(region.size.w),
        h: scaleValue(region.size.h),
      },
      imagePosition: {
        y: scaleValue(region.imagePosition?.y),
        x: scaleValue(region.imagePosition?.x),
      },
      imageSize: {
        w: scaleValue(region.imageSize?.w),
        h: scaleValue(region.imageSize?.h),
      },
    })),
    bubbles:
      comicPanel.bubbles?.map((bubble) => ({
        ...bubble,
        position: {
          y: scaleValue(bubble.position.y),
          x: scaleValue(bubble.position.x),
        },
        size: {
          w: scaleValue(bubble.size.w),
          h: scaleValue(bubble.size.h),
        },
      })) ?? [],
    height: scaleValue(comicPanel.height),
    width: scaleValue(comicPanel.width),
  };
};

export const clearCollection = async (
  collectionRef: CollectionReference<DocumentData>
) => {
  const querySnapshot = await getDocs(collectionRef);
  const batch = writeBatch(editorDatabase);

  for (const doc of querySnapshot.docs) {
    batch.delete(doc.ref);
  }

  await batch.commit();
};

export const extractPromptFromImage = (data: any, variant: string) => {
  if (data.regions) {
    return data.regions[variant].prompt;
  }
  if (data.prompt) {
    return data.prompt;
  }
  return null;
};

export const fetchNameByLora = async (lora: string | undefined) => {
  if (!lora) {
    return "";
  }
  const loraName = extractLoraFromBuiltString(lora);

  const characterCollection = collection(editorDatabase, CHARACTERS_COLLECTION);
  const q = query(characterCollection, where("loraName", "==", loraName));
  const result = await getDocs(q);

  if (result.docs?.length) {
    return result.docs[0].data().name as string;
  }
  return "";
};

const extractLoraFromBuiltString = (builtString: string) => {
  return builtString.split(":")[1].split(":")[0];
};

export const isPageWebtoon = (width: number) => {
  return width === 600;
};

export const generateRandomSeed = () => {
  const min = 1000000;
  const max = 9999999;
  return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
};
