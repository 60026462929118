import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import editorDatabase, {
  SAVED_COMICS_COLLECTION,
  USERS_COLLECTION,
} from "./firebase/firebaseClientConfig";
import type { User } from "./firebase/userOperations";

export const fetchCollaborators = async (comicId: string) => {
  const comicRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  const usersRef = collection(editorDatabase, USERS_COLLECTION);
  const comicSnapshot = await getDoc(comicRef);

  if (!comicSnapshot.exists()) {
    throw new Error("Comic not found");
  }

  const { collaboratorIds } = comicSnapshot.data();

  if (!collaboratorIds || !collaboratorIds.length) return [];

  const userQuery = query(usersRef, where("uid", "in", collaboratorIds));
  const usersSnapshots = await getDocs(userQuery);
  return usersSnapshots.docs.map((doc) => doc.data() as User);
};

export const fetchIsCollaborator = async (
  comicId: string,
  userId: string
): Promise<boolean> => {
  const comicRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  const comicSnapshot = await getDoc(comicRef);

  if (!comicSnapshot.exists()) {
    throw new Error("Comic not found");
  }

  const { collaboratorIds } = comicSnapshot.data();

  if (!collaboratorIds) return false;

  return collaboratorIds.includes(userId);
};

export const addCollaborator = async (
  comicId: string,
  collaboratorId: string
) => {
  const comicRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  const comicSnapshot = await getDoc(comicRef);

  if (!comicSnapshot.exists()) {
    throw new Error("Comic not found");
  }

  const { collaboratorIds } = comicSnapshot.data();

  if (!collaboratorIds) {
    return updateDoc(comicRef, { collaboratorIds: [collaboratorId] });
  }

  return updateDoc(comicRef, {
    collaboratorIds: [...collaboratorIds, collaboratorId],
  });
};

export const removeCollaborator = async (
  comicId: string,
  collaboratorId: string
) => {
  const comicRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  const comicSnapshot = await getDoc(comicRef);

  if (!comicSnapshot.exists()) {
    throw new Error("Comic not found");
  }

  const { collaboratorIds } = comicSnapshot.data();

  if (!collaboratorIds) return;

  const newCollaboratorIds = collaboratorIds.filter(
    (id: string) => id !== collaboratorId
  );
  return updateDoc(comicRef, { collaboratorIds: newCollaboratorIds });
};

export const searchUsers = async (email: string) => {
  const usersRef = collection(editorDatabase, USERS_COLLECTION);

  const q = query(usersRef, where("email", "==", email));
  const usersSnapshot = await getDocs(q);
  return usersSnapshot.docs.map((doc) => {
    return { ...doc.data(), uid: doc.id } as User;
  });
};
