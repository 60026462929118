import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Tag } from "@/src/constants/types/seriesTypes";
import {
  Check,
  ChevronDown,
  ChevronUp,
  Edit,
  PlusCircle,
  Trash,
} from "lucide-react";
import React, { useState } from "react";

interface TagManagerProps {
  currentTags: string[];
  allTags: Tag[];
  onAddTag: (tagName: string) => void;
  onRemoveTag: (tagName: string) => void;
  onAddNewTag: (tagName: string) => Promise<void>;
  onUpdateTag: (tagId: string, updates: Partial<Tag>) => Promise<void>;
  onDeleteTag: (tagId: string) => Promise<void>;
}

export const TagManager: React.FC<TagManagerProps> = ({
  currentTags,
  allTags,
  onAddTag,
  onRemoveTag,
  onAddNewTag,
  onUpdateTag,
  onDeleteTag,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingTagId, setEditingTagId] = useState<string | null>(null);
  const [newTagName, setNewTagName] = useState("");

  const handleTagEdit = async (tagId: string, newName: string) => {
    if (
      newName.trim() &&
      newName !== allTags?.find((t) => t.id === tagId)?.name
    ) {
      await onUpdateTag(tagId, { name: newName.trim() });
    }
    setEditingTagId(null);
    setNewTagName("");
  };

  const handleNewTagAdd = async () => {
    if (
      newTagName.trim() &&
      !allTags?.some((t) => t.name === newTagName.trim())
    ) {
      await onAddNewTag(newTagName.trim());
    }
    setNewTagName("");
  };

  return (
    <div>
      <div className="text-sm text-[#8b898c] font-medium mt-[24px]">Tags</div>
      <div className="flex gap-2 flex-wrap mt-[12px]">
        {currentTags.map((tag) => (
          <Button
            key={tag}
            type="button"
            variant="outline"
            className="flex items-center p-[12px] text-sm whitespace-nowrap hover:bg-[#3d3d3d] cursor-pointer"
            onClick={() => onRemoveTag(tag)}
          >
            {tag}
            <Trash className="ml-1 text-red-300 h-4 w-4" />
          </Button>
        ))}
      </div>

      <div className="flex items-center justify-between mt-[24px]">
        <Button
          type="button"
          variant="ghost"
          className="text-[14px] text-[#8b898c] font-medium"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Add Tags
          {isExpanded ? (
            <ChevronUp className="ml-1" />
          ) : (
            <ChevronDown className="ml-1" />
          )}
        </Button>
        <Button
          variant="ghost"
          type="button"
          className="text-foreground"
          size="icon"
          onClick={() => setIsEditMode(!isEditMode)}
        >
          {isEditMode ? (
            <Check className="h-4 w-4" />
          ) : (
            <Edit className="h-4 w-4" />
          )}
        </Button>
      </div>

      {isExpanded && (
        <div className="flex gap-2 flex-wrap mt-[12px]">
          {allTags
            ?.filter((tag) => isEditMode || !currentTags.includes(tag.name))
            .map((tag) => (
              <div key={tag.id} className="flex items-center">
                {isEditMode && editingTagId === tag.id ? (
                  <Input
                    value={newTagName}
                    onChange={(e) => setNewTagName(e.target.value)}
                    onBlur={() => handleTagEdit(tag.id, newTagName)}
                    onKeyDown={(e) =>
                      e.key === "Enter" && handleTagEdit(tag.id, newTagName)
                    }
                    className="p-[8px] text-[14px] w-[120px]"
                  />
                ) : (
                  <Button
                    variant="outline"
                    type="button"
                    className="flex items-center p-[12px] text-[14px] whitespace-nowrap hover:bg-[#3d3d3d]"
                    onClick={() => {
                      if (isEditMode) {
                        setEditingTagId(tag.id);
                        setNewTagName(tag.name);
                      } else if (!currentTags.includes(tag.name)) {
                        onAddTag(tag.name);
                      }
                    }}
                  >
                    {tag.name}
                    {isEditMode ? (
                      <Trash
                        className="ml-1 text-red-300 cursor-pointer h-4 w-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteTag(tag.id);
                        }}
                      />
                    ) : (
                      <PlusCircle className="ml-1 text-green-300 cursor-pointer h-4 w-4" />
                    )}
                  </Button>
                )}
              </div>
            ))}
          {isEditMode && (
            <div className="flex items-center">
              <Input
                value={newTagName}
                onChange={(e) => setNewTagName(e.target.value)}
                onBlur={handleNewTagAdd}
                onKeyDown={(e) => e.key === "Enter" && handleNewTagAdd()}
                className="p-[8px] text-[14px] w-[120px]"
                placeholder="New tag name"
              />
              <Button
                variant="ghost"
                type="button"
                className="ml-2"
                onClick={handleNewTagAdd}
                disabled={!newTagName.trim()}
              >
                <PlusCircle className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
